import React, { useState } from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import QrReader from "react-qr-scanner"; // Importa el lector de QR adecuado
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

const EscanearQR = () => {
  const [qrData, setQrData] = useState(null);
  const [resultado, setResultado] = useState("");

  const handleScan = (data) => {
    if (data) {
      setQrData(JSON.parse(data.text)); // Extraemos los datos del QR del objeto `text`
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleDescontarVoucher = async () => {
    if (!qrData) {
      alert("Primero escanea un QR válido");
      return;
    }

    try {
      // Aquí podrías realizar alguna operación de descuento en tu lógica
      console.log("QR DATA", qrData);

      const response = await axios.post(`${baseUrl}/auth/descontar-voucher`, {
        tokenQR: qrData.tokenQR,
        monto: qrData.monto,
      });

      setResultado(`Cobro exitoso. Saldo restante: ${response.data.saldoRestante}`);

      // Limpiar los datos del QR tras un cobro exitoso
      //   setTimeout(() => {
      setQrData(null);
      // setResultado("");
      //   }, 3000); // Limpiar los datos después de 3 segundos
    } catch (error) {
      console.error("Error al descontar voucher:", error);
      setResultado(`Error al descontar voucher. ${error.response.data.mensaje}`);

      // Limpiar el resultado y permitir volver a escanear
      //   setTimeout(() => {
      setQrData(null);
      // setResultado("");
      //   }, 3000);
    }
  };

  return (
    <Card sx={{ maxWidth: 400, mx: "auto", my: 5, p: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Escanear QR y Descontar Voucher
        </Typography>
        <Box display="flex" justifyContent="center" mb={3}>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
            constraints={{
              video: { facingMode: { exact: "environment" } }, // Selección de cámara trasera
            }}
          />
        </Box>
        {qrData && (
          <Box>
            <Typography variant="body1">Monto a descontar: {qrData.monto} Gs</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDescontarVoucher}
              fullWidth
            >
              Descontar Voucher
            </Button>
          </Box>
        )}
        {resultado && (
          <Typography
            variant="body1"
            color={resultado.includes("exitoso") ? "success.main" : "error.main"}
            mt={2}
          >
            {resultado}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default EscanearQR;
